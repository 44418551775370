<template>
  <div class="login-container">
    <div class="contain">
      <div class="con-left">
        <div>
          <i class="iconfont icon-logo-04"></i>
          <div class="platName">（帮客智能客服管理系统平台管理）</div>
        </div>
        <div class="plat">v1.2.22</div>
      </div>
      <div class="con-right">
        <span class="line"></span>
        <span class="wel">welcome</span>
        <div>
          <div class="form-item">
            <img
              class="form-icon"
              src="../../assets/images/user_icon.png"
              alt=""
            />
            <input
              v-model="username"
              maxlength="20"
              class="login-input"
              type="text"
              placeholder="请输入用户名"
              @input="
                () => {
                  isFirst = false;
                }
              "
            />
          </div>
          <div class="error" v-if="username || isFirst"></div>
          <div class="error" v-else>请输入用户户名！</div>
          <div class="form-item">
            <img
              class="form-icon"
              src="../../assets/images/password_icon.png"
              alt=""
            />
            <input
              v-model="password"
              maxlength="20"
              class="login-input"
              type="password"
              placeholder="请输入密码"
              @input="
                () => {
                  isFirst = false;
                }
              "
            />
          </div>

          <div class="error" v-if="password || isFirst"></div>
          <div class="error" v-else>请输入密码！</div>
        </div>
        <div class="login-btn" @click="login">登录</div>
        <div class="login-tip">有问题联系管理员</div>
      </div>
    </div>
  </div>
</template>
<script>
import { login } from "../../service/common.js";
import { Config } from "../../utils/index.js";
export default {
  components: {},
  data() {
    return {
      isFirst: true,
      fromRoute: this.$route.query.fromRoute,
      username: "",
      password: ""
    };
  },
  destroyed() {
    window.removeEventListener("keydown", this.loginPress, false);
  },
  mounted() {
    //绑定事件
    window.addEventListener("keydown", this.loginPress);
  },
  methods: {
    loginPress(e) {
      if (e.keyCode == 13) {
        this.login();
      }
    },
    async login() {
      let params = {
        username: this.username,
        password: this.password
      };
			console.log('11111111')
      let loginData = await login(params);
      if (loginData.code == 200) {
        if (loginData.data && loginData.data.token) {
          const Authorization =
            loginData.data.tokenHead + " " + loginData.data.token;
          this.$sto.set("environment", process.env.VUE_APP_CURRENTMODE);
          this.$sto.set(Config.store.token, Authorization);
        }
        if (this.fromRoute) {
          window.location.href = this.fromRoute;
        } else {
          if (loginData.data.haveCompany == 1) {
            this.$router.push({ path: "/create-com" });
          } else {
            this.$router.push({ path: "/index" });
          }
        }
      }
    }
  }
};
</script>
<style lang="less" scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-size: 100% 100%;
  background-color: #ebf0ff;
  background-image: url("../../assets/images/login_background.png");
  // background-size: 2020px 1100px;
  // background-position: 230px center;
  background-repeat: no-repeat;
  .contain {
    display: flex;
    align-items: center;
    height: 100%;
    .con-left {
      width: 535px;
      height: 751px;
      background-image: url("../../assets/images/login_left.png");
      background-size: 100% 100%;
      box-sizing: border-box;
      padding: 301px 0 32px;
      .logo-img {
        width: 360px;
      }
      .icon-logo-04{
        font-size: 40px;
        margin-bottom: 16px;
        color: #fff;
      }
      .platName {
        display: block;
        font-size: 16px;
        color: #fff;
        margin-bottom: 310px;
      }
      .plat {
        font-size: 16px;
        color: #fff;
      }
    }
    .con-right {
      box-sizing: border-box;
      width: 535px;
      height: 751px;
      padding: 0 44px;
      text-align: left;
      background: #fff;
      border-radius: 0 8px 8px 0;
      .line {
        display: block;
        width: 40px;
        height: 6px;
        margin-top: 96px;
        background: #4a76fc;
        border-radius: 8px;
      }
      .wel {
        padding-bottom: 12px;
        font-size: 40px;
        font-weight: bold;
        color: #333333;
      }
      .user {
        width: 32px;
        height: 32px;
      }
      .error {
        font-size: 18px;
        color: #f00;
        margin-bottom: 16px;
      }
      .form-item {
        margin-top: 40px;
        position: relative;
        .form-icon {
          width: 18px;
          height: 18px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 32px;
          margin: auto;
        }
        .login-input {
          padding: 12px 80px;
          width: 100%;
          height: 48px;
          box-sizing: border-box;
          outline: 0;
          font-size: 18px !important;
          border-radius: 8px;
          background: #fff !important;
          &:hover {
            border: 2px solid #4a76ff;
          }
          &::-webkit-input-placeholder {
            color: rgba(118, 118, 118, 0.65);
          }
        }
      }
      .login-btn {
        width: 100%;
        height: 48px;
        line-height: 48px;
        font-size: 18px;
        color: #fff;
        text-align: center;
        background-color: #4a76fc;
        background-size: 100% 100%;
        border-radius: 8px;
        margin-top: 80px;
        cursor: pointer;
      }
      .login-tip {
        text-align: center;
        font-size: 16px;
        color: #b7b7b7;
        margin-top: 160px;
      }
    }
  }
}
</style>
